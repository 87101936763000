<template>
 <v-app :class="($route.name == 'Login' ? 'bg-image' : '')" id="inspire">
    <v-overlay :value="overlay" z-index="203">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <router-view/>
  </v-app>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'main-app',
  created() {
    const lang = require('@/lang/es.js');
    const lang2 = require('@/lang/es2.js');
    const lang3 = require('@/lang/es3.js');
    const lang4 = require('@/lang/es4.js');
    const lang5 = require('@/lang/es5.js');
    let text = JSON.parse(localStorage.getItem('text'));
    let text2 = JSON.parse(localStorage.getItem('text2'));
    let text3 = JSON.parse(localStorage.getItem('text3'));
    let text4 = JSON.parse(localStorage.getItem('text4'));
    let text5 = JSON.parse(localStorage.getItem('text5'));
    if(text || text2 || text3 || text4 || text5) {
      this.setText(text);
      this.setText2(text2);
      this.setText3(text3);
      this.setText4(text4);
      this.setText5(text5);
      this.setLang(localStorage.getItem("lang"));
      //console.log(text);
    }
    else {
      localStorage.setItem("text", JSON.stringify(lang.text));
      localStorage.setItem("text2", JSON.stringify(lang2.text));
      localStorage.setItem("text3", JSON.stringify(lang3.text));
      localStorage.setItem("text4", JSON.stringify(lang4.text));
      localStorage.setItem("text5", JSON.stringify(lang5.text));
      localStorage.setItem("lang", "ES");
      this.setLang("ES");
      this.setText(lang.text);
      this.setText2(lang2.text);
      this.setText3(lang3.text);
      this.setText4(lang4.text);
      this.setText5(lang5.text);
    }
    
    let user = JSON.parse(localStorage.getItem('user'));
    if(user) {
      this.setUser(user);
      this.setPermissions(user.user.roles[0].permissions);
      this.$router.replace({
        name: 'Dashboard'
      })
    }
  },
  computed: {
    ...mapGetters({
      overlay: 'overlay'
    })
  },
  methods: {
    ...mapMutations({
      setUser: 'auth/SET_USER',
      setPermissions: 'auth/SET_PERMISSIONS',
      setLang: 'lang/SET_LANG',
      setText: 'lang/SET_LANG_TEXT',
      setText2: 'lang/SET_LANG_TEXT_2',
      setText3: 'lang/SET_LANG_TEXT_3',
      setText4: 'lang/SET_LANG_TEXT_4',
      setText5: 'lang/SET_LANG_TEXT_5',
    })
  }
}

</script>
