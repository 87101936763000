<template>
  <v-navigation-drawer absolute temporary floating touchless hide-overlay color="third" width="100%" v-model="drawer" style="margin-top:80px !important;" class="py-5 px-5">
    <!--  -->
    <v-list-item>
      <v-list-item-avatar size="60" :color="authenticated.foto ? '' : 'white'">
        <v-img class="shrink" width="100%" :src="authenticated.dir_foto+'/'+authenticated.foto" v-if="authenticated.foto"></v-img>
        <v-icon color="grey" size="60" v-else>account_circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold white--text text-subtitle-1">{{ authenticated?.nombre }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="white mt-2"></v-divider>
    <v-list>
      <!-- TRADUCCIÓN -->
      <v-list-group no-action color="primary" :value="false">
        <template v-slot:activator>         
          <v-list-item-avatar size="60">
            <v-icon color="white" size="35">g_translate</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].dTranslation }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-icon color="white" act>keyboard_arrow_down</v-icon>
        </template>
        <v-list-item @click="resetTrans()">
          <v-list-item-avatar size="60">
            <span class="fi fi-es"></span>          
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].tSpa }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="trans()">
          <v-list-item-avatar size="60">
            <span class="fi fi-us"></span>          
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].tEng }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- ADMIN -->
      <v-list-group no-action color="primary" :value="false" v-if="($can('Usuarios') || $can('Roles') || $can('Admin Histórico') || $can('Empresas') || $can('Clientes') || $can('Locaciones') || $can('Tipos de consumo') || $can('Departamentos') || $can('Países') || $can('Oficinas') || $can('Factores de emisión') || $can('Categorías generales') || $can('Categorías') || $can('Subcategorias'))">
        <template v-slot:activator>
          <v-list-item-avatar size="60">
            <v-icon color="white" size="30">vpn_key</v-icon>
          </v-list-item-avatar>
          <v-list-item-title class="font-weight-normal white--text text-subtitle-1">Admin</v-list-item-title>
        </template>
        <template v-slot:appendIcon>
          <v-icon color="white" act>keyboard_arrow_down</v-icon>
        </template>
        <template v-for="([title, route, permission], i) in admins">
          <v-list-item :key="i" link color="primary" :to="route" v-if="$can(permission)">
            <v-list-item-avatar size="60">
              <v-icon color="white" size="35">account_box</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ title }}</v-list-item-title>         
          </v-list-item>
        </template>
      </v-list-group>
      <!-- DASHBOARD -->
      <v-list-item link exact color="primary" to='/dashboard' v-if="$can('Dashboard')">
        <v-list-item-avatar size="60">         
         <v-img alt="Dashboard-icon" class="" contain :src="require('@/assets/icons/dashboard.png')" max-width="35"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-normal white--text text-subtitle-1">Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- REGISTRO -->
      <v-list-item link exact color="primary" to='/register' v-if="$can('Registro')">
        <v-list-item-avatar size="60">
          <v-icon color="white" size="35">add</v-icon>         
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].dAdd }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- HISTORIAL -->
      <v-list-item link exact color="primary" to='/historical' v-if="$can('Historial')">
        <v-list-item-avatar size="60">         
          <v-img alt="Historico-icon" class="" contain :src="require('../../assets/icons/Historico.png')" max-width="25"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].dHistorical }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- AJUSTES -->
      <v-list-item link exact color="primary" to='/profile' v-if="$can('Ajustes')">
        <v-list-item-avatar size="60">         
          <v-img alt="Ajustes-icon" class="mb-1" contain :src="require('../../assets/icons/ajustes.png')" max-width="30"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].dSettings }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--  -->
      <v-list-item @click="preLogout()">
        <v-list-item-avatar size="60">         
          <v-icon color="white" size="35">exit_to_app</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-normal white--text text-subtitle-1">{{ langText.layout[0].tSignOff }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>   
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "AppDrawerMobile",  
  props: {
    showDrawer: Boolean    
  },
  data() {
    return {
      drawer: null
    }
  },  
  watch:{
    showDrawer(){
      this.drawer = !this.drawer;
    }
  },
  computed:{
    ...mapGetters({
      authenticated: 'auth/authenticated',
      langText: 'lang/text4'
    }),
    admins() {
      let admins = [
        [this.langText.layout[0].dUsers, '/admin/users', 'Usuarios'],
        [this.langText.layout[0].dRoles, '/admin/roles', 'Roles'],
        [this.langText.layout[0].dHistorical, '/admin/historical', 'Admin Histórico'],
        [this.langText.layout[0].dCompanies, '/admin/companies', 'Empresas'],
        [this.langText.layout[0].dCustomers, '/admin/customers', 'Clientes'],
        [this.langText.layout[0].dLocations, '/admin/locations', 'Locaciones'],
        //['Tarifas', '/admin/rates'],
        [this.langText.layout[0].dConsumptionsType, '/admin/consumptions', 'Tipos de consumo'],
        //['Tipos de transportes', '/admin/transports'],
        [this.langText.layout[0].dDepartments, '/admin/departments', 'Departamentos'],
        [this.langText.layout[0].dCountries, '/admin/countries', 'Países'],
        [this.langText.layout[0].dOfficces, '/admin/offices', 'Oficinas'],
        [this.langText.layout[0].dEmissionFactors, '/admin/factors', 'Factores de emisión'],
        [this.langText.layout[0].dGeneralCategories, '/admin/general-categories', 'Categorías generales'],
        [this.langText.layout[0].dCategories, '/admin/categories', 'Categorías'],
        [this.langText.layout[0].dSubcategories, '/admin/subcategories', 'Subcategorias']
      ];
      return admins;
    }
  },
  methods:{
    ...mapActions({
      logout: 'auth/logout',
    }),
    preLogout() {
      swal({ title: this.langText.layout[0].saTitleConfirm, icon: "warning", closeOnClickOutside: false, closeOnEsc: false,
        buttons: {
          cancel:{ text: this.langText.layout[0].ButtonCancel, value: false, visible: true, closeModal: true },
          confirm:{ text: this.langText.layout[0].ButtonAccept, value: true, visible: true, className: "primary", closeModal: false }
        }
      })
      .then(enviar => {
        if (!enviar) throw null;
        var m = document.querySelector(".swal-button--cancel");
        m.setAttribute("disabled", "disabled");
        return this.logout();
      })
      .then( response => {        
        swal({title: this.langText.layout[0].saTitleSuccess, icon: "success", button: this.langText.layout[0].ButtonClose}).then(cerrar => {
          window.location = '/';
          //this.$router.replace({ name: 'Login'});
        })
      })
      .catch((error) => {
        if(error) {
          this.handleError(error)
        }
        else {
          swal.stopLoading();
          swal.close();
        }
      });
    },
    trans() {
      this.translate();
    },
    resetTrans() {
      this.resetTranslate();
    }
  }
}
</script>