export const text = {
    register:[{
        ButtonSend: "Enviar",
        ButtonClose: "Cerrar",
        ButtonCancel: "Cancelar",
        ButtonAccept: "Continuar",
        ButtonAdd: "Registrar",
        saTitleConfirm: "¿Desea enviar los datos del consumo?",
        saTitleSuccess: "Se ha registrado el consumo con éxito",
        mainTitle: "¿Qué tipo de consumo vas a agregar?",
        lDate: "Fecha",
        lMonth: "Mes",
        lObservations: "Observaciones",
        lConsumptionAmountTextGeneral: "Valor númerico del consumo",
        lConsumptionAmountTextTc: "Peso promedio",
        lConsumptionAmountTextTe: "Distancia recorrida por un único trayecto (solo ida)",
        lConsumptionAmountTextVn: "Distancia total recorrida en viaje (Ida y Vuelta)",
        lAditionalConsumptionAmountTextTc: "Distancia envío",
        lNumericalConsumptionAmountTextTc: "Número de envios",
        lNumericalConsumptionAmountTextTe: "Número de trayectos",
        lConsumptionAmountTextC: "Número de muestras",
        lOneOrMore: "Seleccione uno o varios",        
        lcategoriesTextC: "¿Qué combustible usaste?",
        lcategoriesTextE: "¿Qué tipo de energía usaste?",
        lcategoriesTextMp: "¿Qué material usaste?",
        lcategoriesTextTc: "¿Qué tipo de café usaste?",
        lcategoriesTextTe: "¿Qué transporte usaste?",
        lcategoriesTextVn: "¿Qué tipo de viaje de negocios usaste?",
        lcategoriesTextGr: "¿Qué residuo usaste?",
        lcategoriesTextA: "¿Qué tipo de consumo usaste?",
        lcategoriesErrorMessageGeneral: "Seleccione una o varias opciones",
        lsubcategoriesTextC: "Tipo de combustible",
        lsubcategoriesTextE: "Selecciona el tipo de Energía (Marca 'Renovable' solamente par fuentes solares)",
        lsubcategoriesTextMp: "Tipo de materia prima",
        lsubcategoriesTextTc: "Tipo de movimiento",
        lsubcategoriesTextTe: "Tipo de vehículo",
        lsubcategoriesTextVn: "Tipo de transporte",
        lsubcategoriesTextVnRate: "Tarifa",
        lsubcategoriesTextGr: "Selecciona el tipo de disposición",
        lsubcategoriesTextA: "Tipo de consumo",
        lsubcategoriesTextErrorMessageGeneral: "Seleccione una opción",
        lSelectPlaceholder: "Seleccione una opción",
        lConsumptionType: "Tipo de consumo",
        lShippingDestination: "Destino del envío",
        lShippingDistance: "Distancia envío",
        lNumberOfShipments: "Número de envíos",
        lTrips: "¿Cuantos trayectos hiciste?",
        lCustomer: "Cliente",
        lhotel: "Número de noches de hotel",
        lcountry: "País destino",
        rDateRequired: "Fecha es requerido",
        rCustomerRequired: "Cliente es requerido",
        rCountryRequired: "País destino es requerido",
        rDestinationRequired: "Destino de envío es requerido",
        rConsumptionTypeRequired: "Tipo consumo es requerido",
        rHotelRequired: "Número de noches hotel es requerido",
        rHotelMin: "Número de noches hotel debe ser mayor a 0",
        rIsRequired: "es requerido",
        rMin: "debe ser mayor a 0",
        hDistance: "¿Cómo calcular la distancia?"
    }],
    layout: [{
        tWelcome: "Bienvenido",
        tSignOff: "Cerrar sesión",
        ButtonClose: "Cerrar",
        ButtonCancel: "Cancelar",
        ButtonAccept: "Continuar",
        saTitleConfirm: "¿Desea cerrar sesión?",
        saTitleSuccess: "Sesión cerrada con éxito",
        tEng: "Inglés",
        tSpa: "Español",
        dAdd: "Registrar",
        dHistorical: "Histórico",
        dSettings: "Ajustes",
        dCompanies: "Empresas",
        dCustomers: "Clientes",
        dLocations: "Locaciones",
        dConsumptionsType: "Tipos de consumo",
        dDepartments: "Departamentos",
        dCountries: "Paises",
        dOfficces: "Oficinas",
        dEmissionFactors: "Factores de emisión",
        dGeneralCategories: "Categorías generales",
        dCategories: "Categorías",
        dSubcategories: "Subcategorías",
        dTranslation: "Traducción",
        dRoles: "Roles",
        dUsers: "Usuarios",
        dHistorical: "Histórico"
    }]
}