require("./scss/styles.scss");

import Vue from "vue";
//import '@babel/polyfill'
import axios from "axios";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import swal from "sweetalert";
//import './registerServiceWorker'
import { mixins } from "./mixins";
import vuetify from "./plugins/vuetify";
import favicon from "@/assets/logo/logo.png";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const link = document.querySelector("link[rel~='icon']");
link.href = favicon;

const user = JSON.parse(localStorage.getItem("user"));
if (user) {
	const token = user.access_token;
	axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.defaults.baseURL = "https://cfback.azurewebsites.net/api/v1/";
//axios.defaults.baseURL = 'https://carbon-bck-production.up.railway.app/api/v1/';
//axios.defaults.baseURL = 'http://localhost/back_caravela/public/api/v1/';

axios.defaults.withCredentials = true;

Vue.mixin(mixins);

window.axios = axios;

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
