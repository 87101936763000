export default {
  namespaced: true,
  state:{    
    consumptions: [],    
    consumptionCreate: {
      modal: false,
      values: {}
    },
    consumptionEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    consumptions(state){
      return state.consumptions;
    },   
    consumptionCreate(state) {
        return state.consumptionCreate
    },
    consumptionEdit(state) {
      return state.consumptionEdit
    }    
  },
  mutations:{
    SET_CONSUMPTIONS(state, values){
      state.consumptions = values;
    },
    SET_CONSUMPTION_CREATE_MODAL(state, value) {
      state.consumptionCreate.modal = value
    },
    SET_CONSUMPTION_CREATE_VALUES(state, value) {
      state.consumptionCreate.values = value
    },
    SET_CONSUMPTION_EDIT_MODAL(state, value) {
      state.consumptionEdit.modal = value
    },
    SET_CONSUMPTION_EDIT_VALUES(state, value) {
      state.consumptionEdit.values = value
    },
    PUSH_CONSUMPTION(state, value){
      state.consumptions.push(value);
    },    
    SET_UPDATE_CONSUMPTION(state, values){
      var editedIndex = state.consumptions.map(consumption => consumption.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.consumptions[editedIndex], values)              
      }
      return;
    },
    SPLICE_CONSUMPTION(state, value){
      let i = state.consumptions.map(consumption => consumption.id).indexOf(value)      
      state.consumptions.splice(i, 1)
    }
  },
  actions:{
    getConsumptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tiposConsumo').then((response) => {
          commit('SET_CONSUMPTIONS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    consumptionCreate({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tiposConsumo/create').then((response) =>{
          commit('SET_CONSUMPTION_CREATE_VALUES', response.data.data)
          commit('SET_CONSUMPTION_CREATE_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    consumptionStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/tiposConsumo', formData).then((response) => {
          commit('PUSH_CONSUMPTION', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    consumptionEdit({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tiposConsumo/' + value + '/edit').then((response) =>{
          commit('SET_CONSUMPTION_EDIT_VALUES', response.data.data)
          commit('SET_CONSUMPTION_EDIT_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    consumptionUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/tiposConsumo/' + value, payload).then((response) => {          
          commit('SET_UPDATE_CONSUMPTION', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteConsumption({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/tiposConsumo/' + value).then((response) => {
          commit('SPLICE_CONSUMPTION', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}