export default {
  namespaced: true,
  state:{    
    countries: [],    
    countryCreate: {
      modal: false,
      values: {}
    },
    countryEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    countries(state){
      return state.countries;
    },   
    countryCreate(state) {
        return state.countryCreate
    },
    countryEdit(state) {
      return state.countryEdit
    }    
  },
  mutations:{
    SET_COUNTRIES(state, values){
      state.countries = values;
    },
    SET_COUNTRY_CREATE_MODAL(state, value) {
      state.countryCreate.modal = value
    },
    SET_COUNTRY_CREATE_VALUES(state, value) {
      state.countryCreate.values = value
    },
    SET_COUNTRY_EDIT_MODAL(state, value) {
      state.countryEdit.modal = value
    },
    SET_COUNTRY_EDIT_VALUES(state, value) {
      state.countryEdit.values = value
    },
    PUSH_COUNTRY(state, value){
      state.countries.push(value);
    },    
    SET_UPDATE_COUNTRY(state, values){
      var editedIndex = state.countries.map(country => country.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.countries[editedIndex], values)              
      }
      return;
    },
    SPLICE_COUNTRY(state, value){
      let i = state.countries.map(country => country.id).indexOf(value)      
      state.countries.splice(i, 1)
    }
  },
  actions:{
    getCountries({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/paises').then((response) => {
          commit('SET_COUNTRIES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    countryStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/paises', formData).then((response) => {
          commit('PUSH_COUNTRY', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    countryUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/paises/' + value, payload).then((response) => {          
          commit('SET_UPDATE_COUNTRY', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteCountry({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/paises/' + value).then((response) => {
          commit('SPLICE_COUNTRY', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}