export default {
    namespaced: true,
    state:{    
      offices: [],    
      officeCreate: {
        modal: false,
        values: {}
      },
      officeEdit: {
        modal: false,
        values: {}
      }
    },
    getters:{
      offices(state){
        return state.offices;
      },   
      officeCreate(state) {
          return state.officeCreate
      },
      officeEdit(state) {
        return state.officeEdit
      }    
    },
    mutations:{
      SET_OFFICES(state, values){
        state.offices = values;
      },
      SET_OFFICE_CREATE_MODAL(state, value) {
        state.officeCreate.modal = value
      },
      SET_OFFICE_CREATE_VALUES(state, value) {
        state.officeCreate.values = value
      },
      SET_OFFICE_EDIT_MODAL(state, value) {
        state.officeEdit.modal = value
      },
      SET_OFFICE_EDIT_VALUES(state, value) {
        state.officeEdit.values = value
      },
      PUSH_OFFICE(state, value){
        state.offices.push(value);
      },    
      SET_UPDATE_OFFICE(state, values){
        var editedIndex = state.offices.map(office => office.id).indexOf(values.id)      
        if (editedIndex > -1){
          Object.assign(state.offices[editedIndex], values)              
        }
        return;
      },
      SPLICE_OFFICE(state, value){
        let i = state.offices.map(office => office.id).indexOf(value)      
        state.offices.splice(i, 1)
      }
    },
    actions:{
      getOffices({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/oficinas').then((response) => {
            commit('SET_OFFICES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      officeCreate({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/oficinas/create').then((response) =>{
            commit('SET_OFFICE_CREATE_VALUES', response.data.data)
            commit('SET_OFFICE_CREATE_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      officeStore({commit}, payload) {
        const formData = new FormData();
        for(var key in payload) {
            formData.append(key, payload[key]);
        }
        return new Promise((resolve, reject) => {
          axios.post('admin/oficinas', formData).then((response) => {
            commit('PUSH_OFFICE', response.data.data);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      officeEdit({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.get('admin/oficinas/' + value + '/edit').then((response) =>{
            commit('SET_OFFICE_EDIT_VALUES', response.data.data)
            commit('SET_OFFICE_EDIT_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      officeUpdate({commit}, payload) {
        var value = payload.id;            
        return new Promise((resolve, reject) => {
          axios.put('admin/oficinas/' + value, payload).then((response) => {          
            commit('SET_UPDATE_OFFICE', response.data.data);
            resolve(response)   
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      deleteOffice({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.delete('admin/oficinas/' + value).then((response) => {
            commit('SPLICE_OFFICE', value);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });      
      },
    }
  }