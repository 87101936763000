export default {
  namespaced: true,
  state:{    
    user: null,
    permissions: [],
  },
  getters:{
    authenticated(state){
      if(state.user !== null) {        
        return state.user.user;        
      } 
      return null;
    },
    permissions(state) {
      return state.permissions;
    }
  },
  mutations:{  
    SET_USER(state, data){
      state.user = data;      
    },
    SET_USER_DATA(state, data){
      let item = JSON.parse(localStorage.getItem('user'));
      item.user = data;
      localStorage.setItem("user", JSON.stringify(item));
      state.user.user = data;
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    }
  },
  actions:{
    async login({commit}, credentials) {
      return await new Promise((resolve, reject) => {
        axios.post('login', credentials).then((response) => {          
          commit('SET_USER', response.data.data);
          commit('SET_PERMISSIONS', response.data.data.user.roles[0].permissions);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          let user = JSON.parse(localStorage.getItem('user'));
          let token = user.access_token;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          resolve(response)
        })
        .catch((error) => {
          localStorage.removeItem("user");
          reject(error)
        })
      });
    },  
    logout({ commit }){
      return axios.post('logout').then(()=>{      
        commit('SET_USER', null);
        localStorage.removeItem("user");
      })
    },
    async forgot({}, payload) {
      return await new Promise((resolve, reject) => {
        axios.post('forgot-password', payload).then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    async reset({}, payload) {
      return await new Promise((resolve, reject) => {
        axios.post('reset-password', payload).then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    }
  }
}