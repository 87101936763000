export default {
    namespaced: true,
    state:{    
      categories: [],    
      categoryCreate: {
        modal: false,
        values: {}
      },
      categoryEdit: {
        modal: false,
        values: {}
      }
    },
    getters:{
      categories(state){
        return state.categories;
      },   
      categoryCreate(state) {
          return state.categoryCreate
      },
      categoryEdit(state) {
        return state.categoryEdit
      }    
    },
    mutations:{
      SET_CATEGORIES(state, values){
        state.categories = values;
      },
      SET_CATEGORY_CREATE_MODAL(state, value) {
        state.categoryCreate.modal = value
      },
      SET_CATEGORY_CREATE_VALUES(state, value) {
        state.categoryCreate.values = value
      },
      SET_CATEGORY_EDIT_MODAL(state, value) {
        state.categoryEdit.modal = value
      },
      SET_CATEGORY_EDIT_VALUES(state, value) {
        state.categoryEdit.values = value
      },
      PUSH_CATEGORY(state, value){
        state.categories.push(value);
      },    
      SET_UPDATE_CATEGORY(state, values){
        var editedIndex = state.categories.map(category => category.id).indexOf(values.id)      
        if (editedIndex > -1){
          Object.assign(state.categories[editedIndex], values)              
        }
        return;
      },
      SPLICE_CATEGORY(state, value){
        let i = state.categories.map(category => category.id).indexOf(value)      
        state.categories.splice(i, 1)
      }
    },
    actions:{
      getCategories({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/categorias').then((response) => {
            commit('SET_CATEGORIES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      categoryCreate({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/categorias/create').then((response) =>{
            commit('SET_CATEGORY_CREATE_VALUES', response.data.data)
            commit('SET_CATEGORY_CREATE_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      categoryStore({commit}, payload) {
        const formData = new FormData();
        for(var key in payload) {
            formData.append(key, payload[key]);
        }
        return new Promise((resolve, reject) => {
          axios.post('admin/categorias', formData).then((response) => {
            commit('PUSH_CATEGORY', response.data.data);          
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      categoryEdit({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.get('admin/categorias/' + value + '/edit').then((response) =>{
            commit('SET_CATEGORY_EDIT_VALUES', response.data.data)
            commit('SET_CATEGORY_EDIT_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      categoryUpdate({commit}, payload) {
        var value = payload.id;            
        return new Promise((resolve, reject) => {
          axios.put('admin/categorias/' + value, payload).then((response) => {          
            commit('SET_UPDATE_CATEGORY', response.data.data);
            resolve(response)   
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      deleteCategory({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.delete('admin/categorias/' + value).then((response) => {
            commit('SPLICE_CATEGORY', value);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });      
      },
    }
  }