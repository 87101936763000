export default {
    namespaced: true,
    state:{    
      generalCategories: [],    
      generalCategoryCreate: {
        modal: false,
        values: {}
      },
      generalCategoryEdit: {
        modal: false,
        values: {}
      }
    },
    getters:{
      generalCategories(state){
        return state.generalCategories;
      },   
      generalCategoryCreate(state) {
          return state.generalCategoryCreate
      },
      generalCategoryEdit(state) {
        return state.generalCategoryEdit
      }    
    },
    mutations:{
      SET_GENERAL_CATEGORIES(state, values){
        state.generalCategories = values;
      },
      SET_GENERAL_CATEGORY_CREATE_MODAL(state, value) {
        state.generalCategoryCreate.modal = value
      },
      SET_GENERAL_CATEGORY_CREATE_VALUES(state, value) {
        state.generalCategoryCreate.values = value
      },
      SET_GENERAL_CATEGORY_EDIT_MODAL(state, value) {
        state.generalCategoryEdit.modal = value
      },
      SET_GENERAL_CATEGORY_EDIT_VALUES(state, value) {
        state.generalCategoryEdit.values = value
      },
      PUSH_GENERAL_CATEGORY(state, value){
        state.generalCategories.push(value);
      },    
      SET_UPDATE_GENERAL_CATEGORY(state, values){
        var editedIndex = state.generalCategories.map(generalCategory => generalCategory.id).indexOf(values.id)      
        if (editedIndex > -1){
          Object.assign(state.generalCategories[editedIndex], values)              
        }
        return;
      },
      SPLICE_GENERAL_CATEGORY(state, value){
        let i = state.generalCategories.map(generalCategory => generalCategory.id).indexOf(value)      
        state.generalCategories.splice(i, 1)
      }
    },
    actions:{
      getGeneralCategories({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/categoriasGenerales').then((response) => {
            commit('SET_GENERAL_CATEGORIES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      generalCategoryCreate({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/categoriasGenerales/create').then((response) =>{
            commit('SET_GENERAL_CATEGORY_CREATE_VALUES', response.data.data)
            commit('SET_GENERAL_CATEGORY_CREATE_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      generalCategoryStore({commit}, payload) {
        const formData = new FormData();
        for(var key in payload) {
            formData.append(key, payload[key]);
        }
        return new Promise((resolve, reject) => {
          axios.post('admin/categoriasGenerales', formData).then((response) => {
            commit('PUSH_GENERAL_CATEGORY', response.data.data);          
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      generalCategoryEdit({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.get('admin/categoriasGenerales/' + value + '/edit').then((response) =>{
            commit('SET_GENERAL_CATEGORY_EDIT_VALUES', response.data.data)
            commit('SET_GENERAL_CATEGORY_EDIT_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      generalCategoryUpdate({commit}, payload) {
        var value = payload.id;            
        return new Promise((resolve, reject) => {
          axios.put('admin/categoriasGenerales/' + value, payload).then((response) => {          
            commit('SET_UPDATE_GENERAL_CATEGORY', response.data.data);
            resolve(response)   
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      deleteGeneralCategory({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.delete('admin/categoriasGenerales/' + value).then((response) => {
            commit('SPLICE_GENERAL_CATEGORY', value);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });      
      },
    }
  }