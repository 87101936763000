export default {
  namespaced: true,
  state:{
        historical: [],
        historicalEdit: {
            modal: false,
            values: {},
            params: "",
        },
        filters: []
  },
    getters:{
        historical(state){
            return state.historical;
        },   
        filters(state){
            return state.filters;
        }, 
        historicalEdit(state) {
            return state.historicalEdit
        }    
    },
    mutations:{
        SET_HISTORICAL(state, values){
            state.historical = values;
        },
        SET_FILTERS(state, values){
            state.filters = values;
        },
        SET_HISTORICAL_EDIT_MODAL(state, value) {
            state.historicalEdit.modal = value
        },
        SET_HISTORICAL_EDIT_VALUES(state, value) {
            state.historicalEdit.values = value
        },
        SET_HISTORICAL_EDIT_PARAMS(state, value) {
            state.historicalEdit.params = value
        },
        SET_UPDATE_HISTORICAL(state, values){
            var editedIndex = state.historical.map(historic => historic.id).indexOf(values.id)      
            if (editedIndex > -1){
                Object.assign(state.historical[editedIndex], values)              
            }
            return;
        },
    },
    actions:{
        getHistorical({ commit }, filters) {
            return new Promise((resolve, reject) => {
                axios.get('admin/historical', {
                    params: {
                        filters: filters,
                    }
                }).then((response) => {
                    commit('SET_HISTORICAL', response.data.data.data)
                    commit('SET_FILTERS', response.data.data.filters)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        historicalEdit({ commit }, item) {
            return new Promise((resolve, reject) => {
                axios.get('admin/historical/'+item.user_id+'/edit').then((response) =>{
                    commit('SET_HISTORICAL_EDIT_PARAMS', response.data.data)
                    commit('SET_HISTORICAL_EDIT_VALUES', item)
                    commit('SET_HISTORICAL_EDIT_MODAL', true)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        historicalUpdate({commit}, payload) {
            var value = payload.id;            
            return new Promise((resolve, reject) => {
                axios.put('admin/historical/' + value, payload).then((response) => {          
                    commit('SET_UPDATE_HISTORICAL', response.data.data);
                    resolve(response)   
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
    }
}