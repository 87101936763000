export default {
  namespaced: true,
  state:{    
    transports: [],    
    transportCreate: {
      modal: false,
      values: {}
    },
    transportEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    transports(state){
      return state.transports;
    },   
    transportCreate(state) {
        return state.transportCreate
    },
    transportEdit(state) {
      return state.transportEdit
    }    
  },
  mutations:{
    SET_TRANSPORTS(state, values){
      state.transports = values;
    },
    SET_TRANSPORT_CREATE_MODAL(state, value) {
      state.transportCreate.modal = value
    },
    SET_TRANSPORT_CREATE_VALUES(state, value) {
      state.transportCreate.values = value
    },
    SET_TRANSPORT_EDIT_MODAL(state, value) {
      state.transportEdit.modal = value
    },
    SET_TRANSPORT_EDIT_VALUES(state, value) {
      state.transportEdit.values = value
    },
    PUSH_TRANSPORT(state, value){
      state.transports.push(value);
    },    
    SET_UPDATE_TRANSPORT(state, values){
      var editedIndex = state.transports.map(transport => transport.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.transports[editedIndex], values)              
      }
      return;
    },
    SPLICE_TRANSPORT(state, value){
      let i = state.transports.map(transport => transport.id).indexOf(value)      
      state.transports.splice(i, 1)
    }
  },
  actions:{
    getTransports({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tiposTransporte').then((response) => {
          commit('SET_TRANSPORTS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    transportCreate({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tiposTransporte/create').then((response) =>{
          commit('SET_TRANSPORT_CREATE_VALUES', response.data.data)
          commit('SET_TRANSPORT_CREATE_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    transportStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/tiposTransporte', formData).then((response) => {
          commit('PUSH_TRANSPORT', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    transportEdit({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tiposTransporte/' + value + '/edit').then((response) =>{
          commit('SET_TRANSPORT_EDIT_VALUES', response.data.data)
          commit('SET_TRANSPORT_EDIT_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    transportUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/tiposTransporte/' + value, payload).then((response) => {          
          commit('SET_UPDATE_TRANSPORT', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteTransport({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/tiposTransporte/' + value).then((response) => {
          commit('SPLICE_TRANSPORT', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}