<template>  
  <v-app-bar fixed app :elevation="$vuetify.breakpoint.smAndDown ? 1 : 0" :color="$vuetify.breakpoint.smAndDown ? 'third' : 'white'" :height="$vuetify.breakpoint.smAndDown ? 80 : ''">
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-img alt="Greener" class="shrink" contain :src="require('../../assets/logo/logo.png')" transition="scale-transition" max-width="65" v-if="$vuetify.breakpoint.smAndDown "/>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="handleDrawerToggle">
        <v-icon color="white" size="40">menu</v-icon>
      </v-app-bar-nav-icon>
    </template>
    <template v-else>
      <v-spacer></v-spacer>
      <span class="grey--text font-weight-bold body-1">{{ langText.layout[0].tWelcome }}, {{ authenticated?.nombre }}</span>
      <v-menu class="elelvation-1"  origin="center center" transition="slide-y-transition" :nudge-bottom="10" offset-y>
        <template v-slot:activator="{ on }">
          <v-avatar size="40" :color="authenticated.foto ? '' : 'white'" class="ml-4 mr-1" v-on="on" style="cursor:pointer;">
            <v-img class="shrink" width="100%" :src="authenticated.dir_foto+'/'+authenticated.foto" v-if="authenticated.foto"></v-img>
            <v-icon color="grey" size="40" v-else>account_circle</v-icon>
          </v-avatar>
          <!-- <v-icon color="grey" class="ml-4 mr-1" size="40" v-on="on">account_circle</v-icon> -->
        </template>
        <v-list>
          <v-list-item @click="preLogout()">
            <v-list-item-icon class="mr-2">
              <v-icon>exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ langText.layout[0].tSignOff }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>        
      </v-menu>
      <v-menu class="elelvation-1"  origin="center center" transition="slide-y-transition" :nudge-bottom="10" offset-y>
        <template v-slot:activator="{ on }">
          <v-icon color="grey" class="ml-2 mr-1" size="35" v-on="on">g_translate</v-icon>
        </template>
        <v-list>
          <v-list-item @click="resetTrans()">
            <v-list-item-icon class="mr-2">
              <span class="fi fi-es"></span>
              <!-- <v-icon>exit_to_app</v-icon> -->
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ langText.layout[0].tSpa }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="trans()">
            <v-list-item-icon class="mr-2">
              <span class="fi fi-us"></span>
              <!-- <v-icon>exit_to_app</v-icon> -->
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ langText.layout[0].tEng }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>    
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'AppToolbar',
  data(){
    return{
    }
  },
  computed:{
    ...mapGetters({
      authenticated: 'auth/authenticated',
      langText: 'lang/text4'
    })
  },
  methods:{
    ...mapActions({
      logout: 'auth/logout',
    }),
    preLogout() {
      swal({ title: this.langText.layout[0].saTitleConfirm, icon: "warning", closeOnClickOutside: false, closeOnEsc: false,
        buttons: {
          cancel:{ text: this.langText.layout[0].ButtonCancel, value: false, visible: true, closeModal: true },
          confirm:{ text: this.langText.layout[0].ButtonAccept, value: true, visible: true, className: "primary", closeModal: false }
        }
      })
      .then(enviar => {
        if (!enviar) throw null;
        var m = document.querySelector(".swal-button--cancel");
        m.setAttribute("disabled", "disabled");
        return this.logout();
      })
      .then( response => {        
        swal({title: this.langText.layout[0].saTitleSuccess, icon: "success", button: this.langText.layout[0].ButtonClose}).then(cerrar => {
          window.location = '/';
          //this.$router.replace({ name: 'Login'});
        })
      })
      .catch((error) => {
        if(error) {
          this.handleError(error)
        }
        else {
          swal.stopLoading();
          swal.close();
        }
      });
    },
    handleDrawerToggle(){
      this.$emit('side-icon-click')
    },
    trans() {
      this.translate();
    },
    resetTrans() {
      this.resetTranslate();
    }
  }
}
</script>