<template>
  <v-navigation-drawer fixed dark app width="160" class="drounded ma-1" v-model="drawer" color="third" :mobile-breakpoint="960">
    <template v-slot:prepend>
      <v-row class="mb-7 mt-6" justify="center" align="center">
        <v-img alt="Greener" class="shrink" contain :src="require('../../assets/logo/logo.png')" transition="scale-transition" width="110"/>
      </v-row>      
    </template>

    <v-list class="third--text pt-0" flat>

      <v-menu 
        v-model="adminMenu" 
        open-on-hover 
        :close-on-content-click="false" 
        :nudge-width="200" 
        offset-x 
        v-if="($can('Usuarios') || $can('Roles') || $can('Admin Histórico') || $can('Empresas') || $can('Clientes') || $can('Locaciones') || $can('Tipos de consumo') || $can('Departamentos') || $can('Países') || $can('Oficinas') || $can('Factores de emisión') || $can('Categorías generales') || $can('Categorías') || $can('Subcategorias'))"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on"  color="white" class="my-2 px-8">
            <v-list-item-content class="d-flex justify-center align-center text-center py-3" :class="active ? 'primary rounded-lg' : ''" style="height: 80px;">
              <v-icon size="30">vpn_key</v-icon>
              <v-list-item-title class="font-weight-normal subtitle-1">Admin</v-list-item-title> 
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-card>
          <v-list class="py-0">
            <template v-for="([title, route, permission], i) in admins">
              <v-list-item :key="i" :to="route" link exact color="primary" v-if="$can(permission)">
                <v-list-item-icon>
                  <v-icon>account_box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <v-list-item v-slot="{ active }" link to='/dashboard' exact color="white" class="my-2 px-8" v-if="$can('Dashboard')">
        <v-list-item-content class="d-flex justify-center align-center text-center py-3" :class="active ? 'primary rounded-lg' : ''" style="height: 80px;">
          <v-img alt="Dashboard-icon" class="" contain :src="require('../../assets/icons/dashboard.png')" max-width="30"/>
          <v-list-item-title class="font-weight-normal subtitle-1">Dashboard</v-list-item-title> 
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-slot="{ active }" link to='/register' color="white" class="my-2 px-8" v-if="$can('Registro')">
        <v-list-item-content class="d-flex justify-center align-center text-center py-3" :class="active ? 'primary rounded-lg' : ''" style="height: 80px;">
            <v-icon size="30">add</v-icon>
            <v-list-item-title class="font-weight-normal subtitle-1">{{ langText.layout[0].dAdd }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-slot="{ active }" link to='/historical' exact color="white" class="my-2 px-8" v-if="$can('Historial')">
        <v-list-item-content class="d-flex justify-center align-center text-center py-3" :class="active ? 'primary rounded-lg' : ''" style="height: 80px;">
            <v-img alt="Historico-icon" class="" contain :src="require('../../assets/icons/Historico.png')" max-width="20"/>
            <v-list-item-title class="font-weight-normal subtitle-1">{{ langText.layout[0].dHistorical }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>       
    </v-list>

    <template v-slot:append v-if="$can('Ajustes')">
      <v-list class="third--text" flat>
        <v-list-item v-slot="{ active }" link to='/profile' exact color="white" class="px-12">
          <v-list-item-content class="d-flex justify-center text-center" :class="active ? 'primary rounded-lg' : ''">
            <v-img alt="Ajustes-icon" class="mb-1" contain :src="require('../../assets/icons/ajustes.png')" max-width="25"/>
            <v-list-item-title class="font-weight-normal">{{ langText.layout[0].dSettings }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>  
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppDrawer',
  props: {
    showDrawer: Boolean    
  },
  data(){
    return{
      appName: 'Carbon',
      mini: false,
      drawer: true,
      adminMenu: false
    }
  },
  created() {
    //console.log(this.$can('Dashboard'))
  },
  computed: {
    ...mapGetters({
      langText: 'lang/text4'
    }),
    admins() {
      let admins = [
        [this.langText.layout[0].dUsers, '/admin/users', 'Usuarios'],
        [this.langText.layout[0].dRoles, '/admin/roles', 'Roles'],
        [this.langText.layout[0].dHistorical, '/admin/historical', 'Admin Histórico'],
        [this.langText.layout[0].dCompanies, '/admin/companies', 'Empresas'],
        [this.langText.layout[0].dCustomers, '/admin/customers', 'Clientes'],
        [this.langText.layout[0].dLocations, '/admin/locations', 'Locaciones'],
        //['Tarifas', '/admin/rates'],
        [this.langText.layout[0].dConsumptionsType, '/admin/consumptions', 'Tipos de consumo'],
        //['Tipos de transportes', '/admin/transports'],
        [this.langText.layout[0].dDepartments, '/admin/departments', 'Departamentos'],
        [this.langText.layout[0].dCountries, '/admin/countries', 'Países'],
        [this.langText.layout[0].dOfficces, '/admin/offices', 'Oficinas'],
        [this.langText.layout[0].dEmissionFactors, '/admin/factors', 'Factores de emisión'],
        [this.langText.layout[0].dGeneralCategories, '/admin/general-categories', 'Categorías generales'],
        [this.langText.layout[0].dCategories, '/admin/categories', 'Categorías'],
        [this.langText.layout[0].dSubcategories, '/admin/subcategories', 'Subcategorias']
      ];
      return admins;
    }
  }
}
</script>

<style>
  .drounded {
    border-radius: 80px !important;
  }
  .v-navigation-drawer__content {
    overflow-y: hidden !important;
  }
</style>