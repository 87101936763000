export const text = {
    general: {
        ButtonSend: "Enviar",
        ButtonClose: "Cerrar",
        ButtonCancel: "Cancelar",
        ButtonUpdate: "Guardar",        
        //noDataText: "Datos no encontrados"
        // ButtonDelete: "Eliminar",
    },
    settings: {
        form: {
            labels: {
                name: "Nombre",
                lastName: "Apellido",
                city: "Ciudad",
                phone: "Teléfono",
                address: "Dirección de vivienda"
            },
            rules: {
                nameRequired: "Nombre es requerido",
                nameLength: "Nombre no debe ser superior a 60 caracteres",
                lastNameRequired: "Apellido es requerido",
                lastNameLength: "Apellido no debe ser superior a 60 caracteres",
                cityRequired: "Ciudad es requerido",
                cityLength: "Ciudad no debe ser superior a 60 caracteres",
                phoneRequired: "Teléfono es requerido",
                phoneLength: "Teléfono no debe ser superior a 20 caracteres",
                phoneMin: "Teléfono no puede ser menor a 0",
                addressRequired: "Dirección de vivienda es requerido",
                addressLength: "Dirección de vivienda no debe ser superior a 190 caracteres",
                imageType: "Por favor agregue un archivo válido"
            },
            swal: {
                validate: {
                    imageType: "Por favor agregue un archivo válido"
                },
                edit: {
                    titleConfirm: "¿Desea editar los datos?",
                    titleSuccess: "Se han editado los datos con éxito"
                }
            }
        }
    },
    historic: {
        title: "Histórico",
        table: {
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'Todos',
            noDataText: "Datos no encontrados",
            ButtonEdit: "Editar",
            headers: {
                company: "Empresa",
                generalCategory: "Categoría general",
                category: "Categoría",
                consumption: "Consumo",
                activity: "Actividad",
                totalEmission: "Total emisión",
                month: "Mes",
                year: "Año"
            },
            secondary: {
                title: "Información adicional",
                noDataText: "No registra información adicional",
                labels: {
                    consumptionType: "Tipo de consumo",
                    locationTo: "Destino envío",
                    distance: "Distancia envío",
                    activity3Tc: "Número de envíos",
                    activity3Te: "Número de trayectos",
                    customer: "Cliente",
                    country: "País destino",
                    hotel: "Número de noches de hotel",
                    hotelEmission: "Total emisión hotel",
                    wtt: "Total emisión WTT"
                }
            }
        },
        edit: {
            title: "Editar Registro",
            ButtonAccept: "Continuar",
            titleConfirm: "¿Desea editar los datos del consumo?",
            titleSuccess: "Se han editado los datos del consumo con éxito"
        }   
    },
    dashboard: [{
        myEmissions: "Emisiones Locales",
        emissions: "Emisiones",
        barChart: "Emisiones Por Mes",
        pieChart: "Emisiones Por Categoría",
        horizontalChart: "Emisiones Por País",
        localHorizontalChart: "Emisiones Por Área",
        totalTitle: "Total Emisiones",
        treeTitle: "Compensación en Arboles",
        siteFilter: "Sitio de operación"
    }]
}