import Vue from 'vue'
import Vuex from 'vuex'

import auth from "./modules/auth.js";
import country from "./modules/admin/country.js";
import department from "./modules/admin/department.js";
import customer from "./modules/admin/customer.js";
import location from "./modules/admin/location.js";
import rate from "./modules/admin/rate.js";
import transport from "./modules/admin/transport.js";
import office from "./modules/admin/office.js";
import factor from "./modules/admin/factor.js";
import generalCategory from "./modules/admin/generalCategory.js";
import category from "./modules/admin/category.js";
import subcategory from "./modules/admin/subcategory.js";
import quality from "./modules/quality.js";
import company from "./modules/admin/company.js";
import consumption from "./modules/admin/consumption.js";
import lang from "./modules/lang.js";
import historical from "./modules/historical.js";
import historicalA from "./modules/admin/historical.js";
import role from "./modules/admin/role.js";
import user from "./modules/admin/user.js";


Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    overlay: false
  },
  getters:{  
    overlay(state){
      return state.overlay;
    }
  },
  mutations: {
    SET_OVERLAY(state, value){
      state.overlay = value;
    }
  },
  actions: {
    //
  },
  modules: {
    auth,
    country,
    department,
    customer,
    location,
    rate,
    transport,
    office,
    factor,
    generalCategory,
    category,
    subcategory,
    quality,
    company,
    consumption,
    lang,
    historical,
    historicalA,
    role,
    user
  }
})
