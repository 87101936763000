export default {
  namespaced: true,
  state:{    
    rates: [],    
    rateCreate: {
      modal: false,
      values: {}
    },
    rateEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    rates(state){
      return state.rates;
    },   
    rateCreate(state) {
        return state.rateCreate
    },
    rateEdit(state) {
      return state.rateEdit
    }    
  },
  mutations:{
    SET_RATES(state, values){
      state.rates = values;
    },
    SET_RATE_CREATE_MODAL(state, value) {
      state.rateCreate.modal = value
    },
    SET_RATE_CREATE_VALUES(state, value) {
      state.rateCreate.values = value
    },
    SET_RATE_EDIT_MODAL(state, value) {
      state.rateEdit.modal = value
    },
    SET_RATE_EDIT_VALUES(state, value) {
      state.rateEdit.values = value
    },
    PUSH_RATE(state, value){
      state.rates.push(value);
    },    
    SET_UPDATE_RATE(state, values){
      var editedIndex = state.rates.map(rate => rate.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.rates[editedIndex], values)              
      }
      return;
    },
    SPLICE_RATE(state, value){
      let i = state.rates.map(rate => rate.id).indexOf(value)      
      state.rates.splice(i, 1)
    }
  },
  actions:{
    getRates({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tarifas').then((response) => {
          commit('SET_RATES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    rateCreate({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tarifas/create').then((response) =>{
          commit('SET_RATE_CREATE_VALUES', response.data.data)
          commit('SET_RATE_CREATE_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    rateStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/tarifas', formData).then((response) => {
          commit('PUSH_RATE', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    rateEdit({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.get('admin/tarifas/' + value + '/edit').then((response) =>{
          commit('SET_RATE_EDIT_VALUES', response.data.data)
          commit('SET_RATE_EDIT_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    rateUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/tarifas/' + value, payload).then((response) => {          
          commit('SET_UPDATE_RATE', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteRate({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/tarifas/' + value).then((response) => {
          commit('SPLICE_RATE', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}