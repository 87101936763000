import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { publicRoute, protectedRoute } from "./config"

const routes = publicRoute.concat(protectedRoute)

Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const currentUser = store.state.auth.user;
  if(requiresAuth && !currentUser) {
    next('/')
  }
  else if(to.path == '/login' && currentUser){    
    next('/dashboard')
  } 
  else{
    next()
  }
})

export default router
