export default {
  namespaced: true,
  state:{    
    qualities: [],    
    qualityCreate: {
      modal: false,
      values: {}
    }
  },
  getters:{
    qualities(state){
      return state.qualities;
    },   
    qualityCreate(state) {
      return state.qualityCreate
    }
  },
  mutations:{
    SET_QUALITIES(state, values){
      state.qualities = values;
    },
    SET_QUALITY_CREATE_MODAL(state, value) {
      state.qualityCreate.modal = value
    },
    SET_QUALITY_CREATE_VALUES(state, value) {
      state.qualityCreate.values = value
    },
  },
  actions:{
    getQualities({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('quality').then((response) => {
          commit('SET_QUALITIES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    categoryCreate({ commit }) {      
      commit('SET_QUALITY_CREATE_MODAL', true)      
    },
    qualityStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('quality', formData).then((response) => {
          //commit('PUSH_CATEGORY', response.data.data);          
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
  }
}