export default {
    namespaced: true,
    state:{    
        roles: [],    
        roleCreate: {
            modal: false,
            values: {}
        },
        roleEdit: {
            modal: false,
            values: {}
        }
    },
    getters:{
        roles(state){
            return state.roles;
        },   
        roleCreate(state) {
            return state.roleCreate
        },
        roleEdit(state) {
            return state.roleEdit
        }    
    },
    mutations:{
        SET_ROLES(state, values){
            state.roles = values;
        },
        SET_ROLE_CREATE_MODAL(state, value) {
            state.roleCreate.modal = value
        },
        SET_ROLE_CREATE_VALUES(state, value) {
            state.roleCreate.values = value
        },
        SET_ROLE_EDIT_MODAL(state, value) {
            state.roleEdit.modal = value
        },
        SET_ROLE_EDIT_VALUES(state, value) {
            state.roleEdit.values = value
        },
        PUSH_ROLE(state, value){
            state.roles.push(value);
        },    
        SET_UPDATE_ROLE(state, values){
            var editedIndex = state.roles.map(role => role.id).indexOf(values.id)      
            if (editedIndex > -1){
                Object.assign(state.roles[editedIndex], values)              
            }
            return;
        },
        SPLICE_ROLE(state, value){
            let i = state.roles.map(role => role.id).indexOf(value)      
            state.roles.splice(i, 1)
        }
    },
    actions:{
        getRoles({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('admin/roles').then((response) => {
                    commit('SET_ROLES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        roleCreate({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('admin/roles/create').then((response) =>{
                    commit('SET_ROLE_CREATE_VALUES', response.data.data)
                    commit('SET_ROLE_CREATE_MODAL', true)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        roleStore({commit}, payload) {
            const formData = new FormData();
            for(var key in payload) {
                formData.append(key, payload[key]);
            }
            return new Promise((resolve, reject) => {
                axios.post('admin/roles', formData).then((response) => {
                    commit('PUSH_ROLE', response.data.data);          
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        roleEdit({ commit }, value) {
            return new Promise((resolve, reject) => {
                axios.get('admin/roles/' + value + '/edit').then((response) =>{
                    commit('SET_ROLE_EDIT_VALUES', response.data.data)
                    commit('SET_ROLE_EDIT_MODAL', true)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        roleUpdate({commit}, payload) {
            var value = payload.id;            
            return new Promise((resolve, reject) => {
                axios.put('admin/roles/' + value, payload).then((response) => {          
                    commit('SET_UPDATE_ROLE', response.data.data);
                    resolve(response)   
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        deleteRole({ commit }, value) {
            return new Promise((resolve, reject) => {
                axios.delete('admin/roles/' + value).then((response) => {
                    commit('SPLICE_ROLE', value);
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });      
        }
    }
}