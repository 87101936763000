export const text = {
    administration: {
        roles: {
            title: "Roles",
            singleTitle: "Rol",
            form: {
                labels: {
                    name: "Nombre",
                    permissions: "Permisos"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",                    
                    permissionsRequired: "Permisos es requerido"
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del rol?",
                        titleSuccess: "Se ha creado el rol con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del rol?",
                        titleSuccess: "Se ha editado el rol con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del rol?",
                        titleSuccess: "Se ha eliminado el rol con éxito"
                    }
                }
            }
        },
        users: {
            title: "Usuarios",
            singleTitle: "Usuario",
            trueText: "Si",
            falseText: "No",
            usual: "Consumo habitual de agua",
            day: "Consumo de agua al día",
            form: {
                labels: {
                    name: "Nombre",
                    lastname: "Apellido",
                    city: "Ciudad",
                    employee: "Empleado",
                    email: "Correo electrónico",
                    password: "Contraseña",
                    passwordConfirmation: "Confirmación de contraseña",
                    department: "Departamento",
                    officce: "Oficina",
                    role: "Rol",
                    company: "Empresa",
                    phone: "Teléfono",
                    position: "Cargo",
                    usualDistance: "Distancia habitual",
                    distanceHome: "Distancia diaria a casa",
                    address: "Dirección de vivienda"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    lastnameRequired: "Apellido es requerido",
                    lastnameLength: "Apellido no debe ser superior a 60 caracteres",
                    emailRequired: "Correo electrónico es requerido",
                    emailLength: "Correo electrónico no debe ser superior a 80 caracteres",
                    emailFormat: "Correo electrónico es inválido",
                    cityRequired: "Ciudad es requerido",
                    cityLength: "Ciudad no debe ser superior a 60 caracteres",
                    phoneRequired: "Teléfono es requerido",
                    phoneLength: "Teléfono no debe ser superior a 20 caracteres",
                    phoneMin: "Teléfono no puede ser menor a 0",
                    addressRequired: "Dirección de vivienda es requerido",
                    addressLength: "Dirección de vivienda no debe ser superior a 190 caracteres",                                       
                    roleRequired: "Rol es requerido",
                    passwordRequired: "Contraseña es requerido",
                    paswordMin: "Contraseña debe ser igual o superior a 6 caracteres",
                    passwordMax: "Contraseña no debe ser superior a 16 caracteres",
                    passwordConfirmationValidate: "Contraseñas no coinciden"
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del usuario?",
                        titleSuccess: "Se ha creado el usuario con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del usuario?",
                        titleSuccess: "Se ha editado el usuario con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del usuario?",
                        titleSuccess: "Se ha eliminado el usuario con éxito"
                    }
                }
            }
        },
        users2: {
            form: {
                rules: {
                    companyRequired: "Empresa es requerido",
                    positionRequired: "Cargo es requerido",
                    positionLength: "Cargo no debe ser superior a 60 caracteres",
                    officceRequired: "Oficina es requerido",
                    departmentRequired: "Departamento es requerido",
                    usualDistanceMin: "Distancia habitual no puede ser menor a 0",
                    distanceHomeMin: "Distancia diaria a casa no puede ser menor a 0",
                    usualMin: "Consumo habitual de agua no puede ser menor a 0",
                    dayMin: "Consumo de agua al día no puede ser menor a 0"
                }
            }
        }
    }
}