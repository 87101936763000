import { mapGetters, mapMutations } from 'vuex';

export const mixins = {
  computed: {
    ...mapGetters({
      lang: 'lang/lang',
      permissionsB: 'auth/permissions'
    })
  },
  methods: {
    ...mapMutations({
      setLang: 'lang/SET_LANG',
      setText: 'lang/SET_LANG_TEXT',
      setText2: 'lang/SET_LANG_TEXT_2',
      setText3: 'lang/SET_LANG_TEXT_3',
      setText4: 'lang/SET_LANG_TEXT_4',
      setText5: 'lang/SET_LANG_TEXT_5',
      setOverlay: 'SET_OVERLAY'
    }),
    $can(permissionName) {
      return this.permissionsB.map(permission => permission.name).indexOf(permissionName) !== -1;
    },
    calc(number) {
      let with4Decimals = number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
      return with4Decimals;
    },
    handleError(error) {
      var message = error.response.data.message;
      var errors = error.response.data.errors;
      this.errorResponse(message, errors);
    },
    errorResponse(message, errors) {
      var n=0;
      var error = "";
      if (typeof errors == 'string') {
          error = "° " + errors;
      }
      if (typeof errors == 'object') {
          for (n in errors){
              error += "° " + errors[n]+ "\n";
          }
      }
      return swal({
          title: message,
          text: error,
          icon: 'error',
          className: 'swalError',
          buttons:{            
              cancel:{ text: "Cerrar", value: null, visible: true, closeModal: true }
          }
      });
    },
    formatDateTime(value) {     
      if(!value) {
        return false;
      } 
      //var date = new Date(value.replace(/-/g, "/"));
      var date = new Date(value)
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; 
      hours = hours < 10 ? '0'+hours : hours;
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      var day = date.getDate();
      var month = date.getMonth()+1;
      var year = date.getFullYear();
      day = day < 10 ? '0'+day : day;
      month = month < 10 ? '0'+month : month;
      
      return day + "/" + month + "/" + year + "  " + strTime;
    },
    formatDate(value) {
      if(!value) {
        return false;
      }
      //var date = new Date(value.replace(/-/g, "/"));
      var date = new Date(value)
      var day = date.getDate();
      var month = date.getMonth()+1;
      var year = date.getFullYear();
      day = day < 10 ? '0'+day : day;
      month = month < 10 ? '0'+month : month;
      
      return day + "/" + month + "/" + year;
    },
    generateMonths() {
      let cf = 'en-US';
      if(this.lang == "ES") {
        cf = "es-ES"
      }
      let months = [];
      const mot = Array.from({length: 12}, (item, i) => {       
        let month = new Date(0, i).toLocaleString(cf, {month: 'long'});
        month = month.charAt(0).toUpperCase() + month.slice(1);
        let data = {
          id: i+1,
          value: month
        };
        months.push(data);
      });
      return months;
    },
    getMonthName(value) {
      if(!value) {
        return false;
      }
      let cf = 'en-US';
      if(this.lang == "ES") {
        cf = "es-ES"
      }
      const months = Array.from({length: 12}, (item, i) => {       
        return  new Date(0, i).toLocaleString(cf, {month: 'long'});       
      });
      let month = months[value - 1];
      month = month.charAt(0).toUpperCase() + month.slice(1);
      return months[value - 1];
    },
    async translate() {
      if(localStorage.getItem("lang") == "EN") {
        //this.resetTranslate();
        return;
      }
      this.setOverlay(true);
      const lang = require('@/lang/es.js');
      const lang2 = require('@/lang/es2.js');
      const lang3 = require('@/lang/es3.js');
      const lang4 = require('@/lang/es4.js');
      const lang5 = require('@/lang/es5.js');
      await axios.post('translate', {
        text: JSON.stringify(lang.text)
      }).then(response => {
        /*  */
        let res = JSON.parse(response.data.data)        
        /*  */
        axios.post('translate', {
          text: JSON.stringify(lang2.text)
        }).then(response => {
          /*  */
          let res2 = JSON.parse(response.data.data)         
          /*  */
          axios.post('translate', {
            text: JSON.stringify(lang3.text)
          }).then(response => {
            /*  */
            let res3 = JSON.parse(response.data.data)
            /*  */
            axios.post('translate', {
              text: JSON.stringify(lang4.text)
            }).then(response => {
              /*  */
              let res4 = JSON.parse(response.data.data)
              /*  */
              axios.post('translate', {
                text: JSON.stringify(lang5.text)
              }).then(response => {
                /*  */
                let res5 = JSON.parse(response.data.data)

                localStorage.setItem("text", JSON.stringify(res));
                this.setText(res);
                localStorage.setItem("text2", JSON.stringify(res2));
                this.setText2(res2);            
                localStorage.setItem("text3", JSON.stringify(res3));
                this.setText3(res3);
                localStorage.setItem("text4", JSON.stringify(res4));
                this.setText4(res4);
                localStorage.setItem("text5", JSON.stringify(res5));
                this.setText5(res5);
                
                localStorage.setItem("lang", "EN")
                this.setLang("EN");
                this.setOverlay(false);
                /*  */
              })
              .catch(error => {
                this.handleError(error);
                this.setOverlay(false);
              });
            })
            .catch(error => {
              this.handleError(error);
              this.setOverlay(false);
            });
            /*  */          
          })
          .catch(error => {
            this.handleError(error);
            this.setOverlay(false);
          });
          /*  */
        })
        .catch(error => {
          this.handleError(error);
          this.setOverlay(false);
        });
        /*  */
      })
      .catch(error => {
        this.handleError(error);
        this.setOverlay(false);
      });
    },
    resetTranslate() {
      this.setOverlay(true);
      const lang = require('@/lang/es.js');
      const lang2 = require('@/lang/es2.js');
      const lang3 = require('@/lang/es3.js');
      const lang4 = require('@/lang/es4.js');
      const lang5 = require('@/lang/es5.js');
      localStorage.setItem("text", JSON.stringify(lang.text));
      localStorage.setItem("text2", JSON.stringify(lang2.text));
      localStorage.setItem("text3", JSON.stringify(lang3.text));
      localStorage.setItem("text4", JSON.stringify(lang4.text));
      localStorage.setItem("text5", JSON.stringify(lang5.text));
      localStorage.setItem("lang", "ES")
      this.setLang("ES");
      this.setText(lang.text);
      this.setText2(lang2.text);
      this.setText3(lang3.text);
      this.setText4(lang4.text);
      this.setText5(lang5.text);
      this.setOverlay(false);
    }
  }
}