import { LoginLayout, DefaultLayout } from '@/layouts';

export const publicRoute = [
  {
    path: '*',  
    name: 'notFound',  
    component: () => import(/* webpackChunkName: "NotFound" */'@/views/errors/NotFound.vue')
  },
  {
    path: '/',
    component: LoginLayout,
    redirect: "/login",
    hidden: true,
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: { title: 'Login' },
        component: () => import(/* webpackChunkName: "Login" */'@/views/Login.vue')
      }
    ]
  }
];

export const protectedRoute = [
  {
    path: "/dashboard",
    component: DefaultLayout,
    meta: {      
      requiresAuth: true
    },
    children: [
      {    
        path: '',
        name: 'Dashboard',
        meta: {
          title: 'Dashboard',          
        },
        component: () => import(/* webpackChunkName: "Dashboard" */'@/views/Dashboard.vue') 
      },
      {    
        path: '/register',
        component: () => import(/* webpackChunkName: "Register" */'@/views/Register.vue'),
        children: [
          {
            path: '',
            name: 'register.main',
            meta: { title: 'Register'},
            component: () => import(/* webpackChunkName: "Register-Main" */'@/components/register/Main.vue'),
          },
          {
            name: 'add',
            path: '/register/add',
            component: () => import('@/components/register/Add.vue'),
          }
        ]
      },
      {    
        path: '/historical',
        name: 'Historical',
        meta: {
          title: 'Historical',          
        },
        component: () => import(/* webpackChunkName: "Historical" */'@/views/Historical.vue') 
      },
      {    
        path: '/profile',
        name: 'Profile',
        meta: {
          title: 'Profile',          
        },
        component: () => import(/* webpackChunkName: "Profile" */'@/views/Profile.vue')
      },
      {    
        path: '/quality',
        name: 'Quality',
        meta: {
          title: 'Quality',          
        },
        component: () => import(/* webpackChunkName: "Quality" */'@/views/Quality.vue')
      },
    ]
  },
  /* ADMINISTRACIÓN */
  {
    path: '/admin',
    component: DefaultLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'users',        
        meta: {
          title: 'Admin - Users'
        },
        component: () => import(/* webpackChunkName: "Users" */'@/views/admin/Users.vue')
      },
      {
        path: 'roles',        
        meta: {
          title: 'Admin - Roles'
        },
        component: () => import(/* webpackChunkName: "Roles" */'@/views/admin/Roles.vue')
      },
      {
        path: 'historical',        
        meta: {
          title: 'Admin - Historical'
        },
        component: () => import(/* webpackChunkName: "Historical" */'@/views/admin/Historical.vue')
      },
      {
        path: 'companies',        
        meta: {
          title: 'Admin - Companies'
        },
        component: () => import(/* webpackChunkName: "Companies" */'@/views/admin/Companies.vue')
      },
      /* webpackChunkName: "Rates" */
      /* {
        path: 'rates',        
        meta: {
          title: 'Admin - Rates'
        },
        component: () => import('@/views/admin/Rates.vue')
      }, */
      {
        path: 'locations',        
        meta: {
          title: 'Admin - Locations'
        },
        component: () => import(/* webpackChunkName: "Locations" */'@/views/admin/Locations.vue')
      },
      {
        path: 'customers',        
        meta: {
          title: 'Admin - Customers'
        },
        component: () => import(/* webpackChunkName: "Customers" */'@/views/admin/Customers.vue')
      },
      /* webpackChunkName: "Transports" */
      /* {
        path: 'transports',        
        meta: {
          title: 'Admin - Transports'
        },
        component: () => import('@/views/admin/Transports.vue')
      }, */
      {
        path: 'countries',        
        meta: {
          title: 'Admin - Countries'
        },
        component: () => import(/* webpackChunkName: "Countries" */'@/views/admin/Countries.vue')
      },
      {        
        path: 'departments',
        meta: {
          title: 'Admin - Departments'
        },
        component: () => import(/* webpackChunkName: "Departments" */'@/views/admin/Departments.vue')
      },
      {        
        path: 'offices',
        meta: {
          title: 'Admin - Offices'
        },
        component: () => import(/* webpackChunkName: "Offices" */'@/views/admin/Offices.vue')
      },
      {        
        path: 'factors',
        meta: {
          title: 'Admin - Factors'
        },
        component: () => import(/* webpackChunkName: "Factors" */'@/views/admin/Factors.vue')
      },
      {        
        path: 'general-categories',
        meta: {
          title: 'Admin - General Categories'
        },
        component: () => import(/* webpackChunkName: "GeneralCategories" */'@/views/admin/GeneralCategories.vue')
      },
      {        
        path: 'categories',
        meta: {
          title: 'Admin - Categories'
        },
        component: () => import(/* webpackChunkName: "Categories" */'@/views/admin/Categories.vue')
      },
      {        
        path: 'subcategories',
        meta: {
          title: 'Admin - Subcategories'
        },
        component: () => import(/* webpackChunkName: "Subcategories" */'@/views/admin/Subcategories.vue')
      },
      {
        path: 'consumptions',        
        meta: {
          title: 'Admin - Consumptions'
        },
        component: () => import(/* webpackChunkName: "Consumptions" */'@/views/admin/Consumptions.vue')
      },
    ]
  }
];