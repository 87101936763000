export const text = {
    administration: {
        general: {
            ButtonSend: "Enviar",
            ButtonClose: "Cerrar",
            ButtonCancel: "Cancelar",
            ButtonAccept: "Continuar",
            ButtonDelete: "Eliminar",
            noDataText: "Datos no encontrados",
            enName: 'Nombre alternativo',
            enNameRequired: "Nombre alternativo es requerido",
            enNameLength: "Nombre alternativo no debe ser superior a 60 caracteres",
            create: {
                title: 'Registrar'
            },
            edit: {
                title: 'Editar'
            },
            list: {
                searchText: "Buscar",
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'Todos',
                createdText: 'Creado',
                updatedText: 'Actualizado',
                actions: 'Acciones'
            }
        },      
        generalCategories: {
            title: "Categorías generales",
            singleTitle: "Categoria general",
            form: {
                labels: {
                    name: "Nombre",
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres"
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos de la categoría general?",
                        titleSuccess: "Se ha creado la categoría general con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos de la categoría general?",
                        titleSuccess: "Se ha editado la categoría general con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos de la categoría general?",
                        titleSuccess: "Se ha eliminado la categoría general con éxito"
                    }
                }
            }
        },
        locations: {
            title: "Locaciones",
            singleTitle: "Locación",
            form: {
                labels: {
                    name: "Nombre",
                    company: "Empresa"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    companyRequired: "Empresa es requerido",                    
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos de la locación?",
                        titleSuccess: "Se ha creado la locación con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos de la locación?",
                        titleSuccess: "Se ha editado la locación con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos de la locación?",
                        titleSuccess: "Se ha eliminado la locación con éxito"
                    }
                }
            }
        },
        offices: {
            title: "Oficinas",
            singleTitle: "Oficina",
            form: {
                labels: {
                    name: "Nombre",
                    country: "País",
                    company: "Empresa",
                    address: "Dirección",
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    companyRequired: "Empresa es requerido",
                    countryRequired: "Empresa es requerido",
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos de la oficina?",
                        titleSuccess: "Se ha creado la oficina con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos de la oficina?",
                        titleSuccess: "Se ha editado la oficina con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos de la oficina?",
                        titleSuccess: "Se ha eliminado la oficina con éxito"
                    }
                }
            }
        },
        subcategories: {
            title: "Subcategorías",
            singleTitle: "Subcategoría",
            form: {
                labels: {
                    name: "Nombre",
                    inverseUnit: "Unidad inversa",
                    categories: "Categorías",
                    emissionFactors: "Factores de emisión",
                    value: "Valor"
                },
                rules: {
                    categoriesRequired: "Categorías es requerido",
                    factorsRequired: "Factores de emisión es requerido",
                    valueRequired: "Valor es requerido",
                    valueMin: "Valor debe ser mayor a 0",
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    inverseUnitRequired: "Unidad inversa es requerido",
                    inverseUnitLength: "Unidad inversa no debe ser superior a 60 caracteres",
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos de la subcategoría?",
                        titleSuccess: "Se ha creado la subcategoría con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos de la subcategoría?",
                        titleSuccess: "Se ha editado la subcategoría con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos de la subcategoría?",
                        titleSuccess: "Se ha eliminado la subcategoría con éxito"
                    }
                }
            }
        }
    }
}