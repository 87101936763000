export default {
  namespaced: true,
  state:{    
    departments: [],    
    departmentCreate: {
      modal: false,
      values: {}
    },
    departmentEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    departments(state){
      return state.departments;
    },   
    departmentCreate(state) {
        return state.departmentCreate
    },
    departmentEdit(state) {
      return state.departmentEdit
    }    
  },
  mutations:{
    SET_DEPARTMENTS(state, values){
      state.departments = values;
    },
    SET_DEPARTMENT_CREATE_MODAL(state, value) {
      state.departmentCreate.modal = value
    },
    SET_DEPARTMENT_CREATE_VALUES(state, value) {
      state.departmentCreate.values = value
    },
    SET_DEPARTMENT_EDIT_MODAL(state, value) {
      state.departmentEdit.modal = value
    },
    SET_DEPARTMENT_EDIT_VALUES(state, value) {
      state.departmentEdit.values = value
    },
    PUSH_DEPARTMENT(state, value){
      state.departments.push(value);
    },    
    SET_UPDATE_DEPARTMENT(state, values){
      var editedIndex = state.departments.map(department => department.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.departments[editedIndex], values)              
      }
      return;
    },
    SPLICE_DEPARTMENT(state, value){
      let i = state.departments.map(department => department.id).indexOf(value)      
      state.departments.splice(i, 1)
    }
  },
  actions:{
    getDepartments({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/departamentos').then((response) => {
          commit('SET_DEPARTMENTS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    departmentCreate({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/departamentos/create').then((response) =>{
          commit('SET_DEPARTMENT_CREATE_VALUES', response.data.data)
          commit('SET_DEPARTMENT_CREATE_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    departmentStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/departamentos', formData).then((response) => {
          commit('PUSH_DEPARTMENT', response.data.data);          
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    departmentEdit({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.get('admin/departamentos/' + value + '/edit').then((response) =>{
          commit('SET_DEPARTMENT_EDIT_VALUES', response.data.data)
          commit('SET_DEPARTMENT_EDIT_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    departmentUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/departamentos/' + value, payload).then((response) => {          
          commit('SET_UPDATE_DEPARTMENT', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteDepartment({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/departamentos/' + value).then((response) => {
          commit('SPLICE_DEPARTMENT', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}