export default {
  namespaced: true,
  state:{    
    locations: [],    
    locationCreate: {
      modal: false,
      values: {}
    },
    locationEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    locations(state){
      return state.locations;
    },   
    locationCreate(state) {
        return state.locationCreate
    },
    locationEdit(state) {
      return state.locationEdit
    }    
  },
  mutations:{
    SET_LOCATIONS(state, values){
      state.locations = values;
    },
    SET_LOCATION_CREATE_MODAL(state, value) {
      state.locationCreate.modal = value
    },
    SET_LOCATION_CREATE_VALUES(state, value) {
      state.locationCreate.values = value
    },
    SET_LOCATION_EDIT_MODAL(state, value) {
      state.locationEdit.modal = value
    },
    SET_LOCATION_EDIT_VALUES(state, value) {
      state.locationEdit.values = value
    },
    PUSH_LOCATION(state, value){
      state.locations.push(value);
    },    
    SET_UPDATE_LOCATION(state, values){
      var editedIndex = state.locations.map(location => location.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.locations[editedIndex], values)              
      }
      return;
    },
    SPLICE_LOCATION(state, value){
      let i = state.locations.map(location => location.id).indexOf(value)      
      state.locations.splice(i, 1)
    }
  },
  actions:{
    getLocations({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/locaciones').then((response) => {
          commit('SET_LOCATIONS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    locationCreate({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/locaciones/create').then((response) =>{
          commit('SET_LOCATION_CREATE_VALUES', response.data.data)
          commit('SET_LOCATION_CREATE_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    locationStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/locaciones', formData).then((response) => {
          commit('PUSH_LOCATION', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    locationEdit({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.get('admin/locaciones/' + value + '/edit').then((response) =>{
          commit('SET_LOCATION_EDIT_VALUES', response.data.data)
          commit('SET_LOCATION_EDIT_MODAL', true)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    locationUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/locaciones/' + value, payload).then((response) => {          
          commit('SET_UPDATE_LOCATION', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteLocation({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/locaciones/' + value).then((response) => {
          commit('SPLICE_LOCATION', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}