export default {
  namespaced: true,
  state:{    
    companies: [],    
    companyCreate: {
      modal: false,
      values: {}
    },
    companyEdit: {
      modal: false,
      values: {}
    }
  },
  getters:{
    companies(state){
      return state.companies;
    },   
    companyCreate(state) {
        return state.companyCreate
    },
    companyEdit(state) {
      return state.companyEdit
    }    
  },
  mutations:{
    SET_COMPANIES(state, values){
      state.companies = values;
    },
    SET_COMPANY_CREATE_MODAL(state, value) {
      state.companyCreate.modal = value
    },
    SET_COMPANY_CREATE_VALUES(state, value) {
      state.companyCreate.values = value
    },
    SET_COMPANY_EDIT_MODAL(state, value) {
      state.companyEdit.modal = value
    },
    SET_COMPANY_EDIT_VALUES(state, value) {
      state.companyEdit.values = value
    },
    PUSH_COMPANY(state, value){
      state.companies.push(value);
    },    
    SET_UPDATE_COMPANY(state, values){
      var editedIndex = state.companies.map(company => company.id).indexOf(values.id)      
      if (editedIndex > -1){
        Object.assign(state.companies[editedIndex], values)              
      }
      return;
    },
    SPLICE_COMPANY(state, value){
      let i = state.companies.map(company => company.id).indexOf(value)      
      state.companies.splice(i, 1)
    }
  },
  actions:{
    getCompanies({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/companies').then((response) => {
          commit('SET_COMPANIES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    companyStore({commit}, payload) {
      const formData = new FormData();
      for(var key in payload) {
          formData.append(key, payload[key]);
      }
      return new Promise((resolve, reject) => {
        axios.post('admin/companies', formData).then((response) => {
          commit('PUSH_COMPANY', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    companyUpdate({commit}, payload) {
      var value = payload.id;            
      return new Promise((resolve, reject) => {
        axios.put('admin/companies/' + value, payload).then((response) => {          
          commit('SET_UPDATE_COMPANY', response.data.data);
          resolve(response)   
        })
        .catch((error) => {
          reject(error)
        })
      });
    },
    deleteCompany({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios.delete('admin/companies/' + value).then((response) => {
          commit('SPLICE_COMPANY', value);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      });      
    },
  }
}