export default {
  namespaced: true,
  state:{    
    text: null,
    text2: null,
    text3: null,
    text4: null,
    text5: null,
    lang: null,
  },
  getters:{
    text(state){
      return state.text;
    },
    text2(state){
      return state.text2;
    },
    text3(state){
      return state.text3;
    },
    text4(state){
      return state.text4;
    },
    text5(state){
      return state.text5;
    },
    lang(state) {
      return state.lang;
    }
  },
  mutations:{    
    SET_LANG_TEXT(state, value) {
      state.text = value
    },
    SET_LANG_TEXT_2(state, value) {
      state.text2 = value
    },
    SET_LANG_TEXT_3(state, value) {
      state.text3 = value
    },
    SET_LANG_TEXT_4(state, value) {
      state.text4 = value
    },
    SET_LANG_TEXT_5(state, value) {
      state.text5 = value
    },
    SET_LANG(state, value) {
      state.lang = value
    }
  },
  actions:{
    //
  }
}