export default {
    namespaced: true,
    state:{    
      factors: [],    
      factorCreate: {
        modal: false,
        values: {}
      },
      factorEdit: {
        modal: false,
        values: {}
      }
    },
    getters:{
      factors(state){
        return state.factors;
      },   
      factorCreate(state) {
          return state.factorCreate
      },
      factorEdit(state) {
        return state.factorEdit
      }    
    },
    mutations:{
      SET_FACTORS(state, values){
        state.factors = values;
      },
      SET_FACTOR_CREATE_MODAL(state, value) {
        state.factorCreate.modal = value
      },
      SET_FACTOR_CREATE_VALUES(state, value) {
        state.factorCreate.values = value
      },
      SET_FACTOR_EDIT_MODAL(state, value) {
        state.factorEdit.modal = value
      },
      SET_FACTOR_EDIT_VALUES(state, value) {
        state.factorEdit.values = value
      },
      PUSH_FACTOR(state, value){
        state.factors.push(value);
      },    
      SET_UPDATE_FACTOR(state, values){
        var editedIndex = state.factors.map(factor => factor.id).indexOf(values.id)      
        if (editedIndex > -1){
          Object.assign(state.factors[editedIndex], values)              
        }
        return;
      },
      SPLICE_FACTOR(state, value){
        let i = state.factors.map(factor => factor.id).indexOf(value)      
        state.factors.splice(i, 1)
      }
    },
    actions:{
      getFactors({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/factoresEmision').then((response) => {
            commit('SET_FACTORS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      factorCreate({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/factoresEmision/create').then((response) =>{
            commit('SET_FACTOR_CREATE_VALUES', response.data.data)
            commit('SET_FACTOR_CREATE_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      factorStore({commit}, payload) {
        const formData = new FormData();
        for(var key in payload) {
            formData.append(key, payload[key]);
        }
        return new Promise((resolve, reject) => {
          axios.post('admin/factoresEmision', formData).then((response) => {
            commit('PUSH_FACTOR', response.data.data);          
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      factorEdit({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.get('admin/factoresEmision/' + value + '/edit').then((response) =>{
            commit('SET_FACTOR_EDIT_VALUES', response.data.data)
            commit('SET_FACTOR_EDIT_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      factorUpdate({commit}, payload) {
        var value = payload.id;            
        return new Promise((resolve, reject) => {
          axios.put('admin/factoresEmision/' + value, payload).then((response) => {          
            commit('SET_UPDATE_FACTOR', response.data.data);
            resolve(response)   
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      deleteFactor({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.delete('admin/factoresEmision/' + value).then((response) => {
            commit('SPLICE_FACTOR', value);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });      
      },
    }
  }