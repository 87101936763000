export default {
    namespaced: true,
    state:{    
      subcategories: [],    
      subcategoryCreate: {
        modal: false,
        values: {}
      },
      subcategoryEdit: {
        modal: false,
        values: {}
      }
    },
    getters:{
      subcategories(state){
        return state.subcategories;
      },   
      subcategoryCreate(state) {
          return state.subcategoryCreate
      },
      subcategoryEdit(state) {
        return state.subcategoryEdit
      }    
    },
    mutations:{
      SET_SUBCATEGORIES(state, values){
        state.subcategories = values;
      },
      SET_SUBCATEGORY_CREATE_MODAL(state, value) {
        state.subcategoryCreate.modal = value
      },
      SET_SUBCATEGORY_CREATE_VALUES(state, value) {
        state.subcategoryCreate.values = value
      },
      SET_SUBCATEGORY_EDIT_MODAL(state, value) {
        state.subcategoryEdit.modal = value
      },
      SET_SUBCATEGORY_EDIT_VALUES(state, value) {
        state.subcategoryEdit.values = value
      },
      PUSH_SUBCATEGORY(state, value){
        state.subcategories.push(value);
      },    
      SET_UPDATE_SUBCATEGORY(state, values){
        var editedIndex = state.subcategories.map(subcategory => subcategory.id).indexOf(values.id)      
        if (editedIndex > -1){
          Object.assign(state.subcategories[editedIndex], values)              
        }
        return;
      },
      SPLICE_SUBCATEGORY(state, value){
        let i = state.subcategories.map(subcategory => subcategory.id).indexOf(value)      
        state.subcategories.splice(i, 1)
      }
    },
    actions:{
      getSubcategories({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/subcategorias').then((response) => {
            commit('SET_SUBCATEGORIES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      subcategoryCreate({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get('admin/subcategorias/create').then((response) =>{
            commit('SET_SUBCATEGORY_CREATE_VALUES', response.data.data)
            commit('SET_SUBCATEGORY_CREATE_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      subcategoryStore({commit}, payload) {
        const formData = new FormData();
        for(var key in payload) {
            formData.append(key, payload[key]);
        }
        return new Promise((resolve, reject) => {
          axios.post('admin/subcategorias', formData).then((response) => {
            commit('PUSH_SUBCATEGORY', response.data.data);          
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      subcategoryEdit({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.get('admin/subcategorias/' + value + '/edit').then((response) =>{
            commit('SET_SUBCATEGORY_EDIT_VALUES', response.data.data)
            commit('SET_SUBCATEGORY_EDIT_MODAL', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      subcategoryUpdate({commit}, payload) {
        var value = payload.id;            
        return new Promise((resolve, reject) => {
          axios.put('admin/subcategorias/' + value, payload).then((response) => {          
            commit('SET_UPDATE_SUBCATEGORY', response.data.data);
            resolve(response)   
          })
          .catch((error) => {
            reject(error)
          })
        });
      },
      deleteSubcategory({ commit }, value) {
        return new Promise((resolve, reject) => {
          axios.delete('admin/subcategorias/' + value).then((response) => {
            commit('SPLICE_SUBCATEGORY', value);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        });      
      },
    }
  }