<template>
  <div>
    <AppDrawer v-if="$vuetify.breakpoint.mdAndUp"></AppDrawer>
    <AppDrawerMobile :show-drawer="showDrawer" v-if="$vuetify.breakpoint.smAndDown"></AppDrawerMobile>
    <AppToolbar @side-icon-click="handleDrawerVisiable"></AppToolbar>    
    <v-main>
      <v-container fluid class="pa-6">
        <v-row no-gutters>
          <v-col cols="12" sm="12">           
            <router-view :key="$route.path"></router-view>
          </v-col>
        </v-row>
      </v-container>      
    </v-main>
  </div>
</template>

<script>

import AppDrawer from '@/components/layout/AppDrawer.vue';
import AppToolbar from '@/components/layout/AppToolbar.vue';
import AppDrawerMobile from '@/components/layout/AppDrawerMobile.vue';

export default {
  name: 'DefaultLayout',
  components:{
    AppDrawer, AppToolbar, AppDrawerMobile
  },
  data(){
    return{
      showDrawer: true
    }
  },
  methods:{
    handleDrawerVisiable(){      
      this.showDrawer = !this.showDrawer
    }
  }
}
</script>

<style>
/* @media (min-width: 1264px) {
  .v-dialog{
    margin-left: 250px !important;
  }
  .swal2-modal{
     margin-right: 274px !important;
  }
} */
</style>