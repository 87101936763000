export const text = {
    administration: {
        general: {
            ButtonSend: "Enviar",
            ButtonClose: "Cerrar",
            ButtonCancel: "Cancelar",
            ButtonAccept: "Continuar",
            ButtonDelete: "Eliminar",
            noDataText: "Datos no encontrados",
            create: {
                title: 'Registrar'
            },
            edit: {
                title: 'Editar'
            },
            list: {
                filters: "Filtros",
                searchText: "Buscar",
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'Todos',
                createdText: 'Creado',
                updatedText: 'Actualizado',
                actions: 'Acciones'
            }
        },
        categories: {
            title: "Categorías",
            singleTitle: "Categoría",
            form: {
                labels: {
                    name: "Nombre",
                    generalCategory: "Categoría general",
                    departments: "Departamentos",
                    type: "Tipo",
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    generalCategoryRequired: "Categoría general es requerido",
                    departmentsRequired: "Departamentos es requerido",
                    typeRequired: "Tipo es requerido"
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos de la categoría?",
                        titleSuccess: "Se ha creado la categoría con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos de la categoría?",
                        titleSuccess: "Se ha editado la categoría con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos de la categoría?",
                        titleSuccess: "Se ha eliminado la categoría con éxito"
                    }
                }
            }
        },
        companies: {
            title: "Empresas",
            singleTitle: "Empresa",
            form: {
                labels: {
                    name: "Nombre",                   
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",                    
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos de la empresa?",
                        titleSuccess: "Se ha creado la empresa con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos de la empresa?",
                        titleSuccess: "Se ha editado la empresa con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos de la empresa?",
                        titleSuccess: "Se ha eliminado la empresa con éxito"
                    }
                }
            }
        },
        consumptions: {
            title: "Tipos de consumo",
            singleTitle: "Tipo de consumo",
            form: {
                labels: {
                    name: "Nombre",
                    company: "Empresa"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    companyRequired: "Empresa es requerido"
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del tipo de consumo?",
                        titleSuccess: "Se ha creado el tipo de consumo con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del tipo de consumo?",
                        titleSuccess: "Se ha editado el tipo de consumo con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del tipo de consumo?",
                        titleSuccess: "Se ha eliminado el tipo de consumo con éxito"
                    }
                }
            }
        },
        countries: {
            title: "Países",
            singleTitle: "País",
            form: {
                labels: {
                    name: "Nombre",
                    //iso: "ISO"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    ISORequired: "es requerido",
                    ISOLength: "no debe ser superior a 60 caracteres"
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del país?",
                        titleSuccess: "Se ha creado el país con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del país?",
                        titleSuccess: "Se ha editado el país con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del país?",
                        titleSuccess: "Se ha eliminado el país con éxito"
                    }
                }
            }
        },
        customers: {
            title: "Clientes",
            singleTitle: "Cliente",
            form: {
                labels: {
                    name: "Nombre",
                    company: "Empresa"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    companyRequired: "Empresa es requerido",                    
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del cliente?",
                        titleSuccess: "Se ha creado el cliente con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del cliente?",
                        titleSuccess: "Se ha editado el cliente con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del cliente?",
                        titleSuccess: "Se ha eliminado el cliente con éxito"
                    }
                }
            }
        },
        departments: {
            title: "Departamentos",
            singleTitle: "Departamento",
            form: {
                labels: {
                    name: "Nombre",
                    company: "Empresa"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    companyRequired: "Empresa es requerido",                    
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del departamento?",
                        titleSuccess: "Se ha creado el departamento con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del departamento?",
                        titleSuccess: "Se ha editado el departamento con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del departamento?",
                        titleSuccess: "Se ha eliminado el departamento con éxito"
                    }
                }
            }
        },
        factors: {
            title: "Factores",
            singleTitle: "Factor",
            trueText: "Si",
            falseText: "No",
            form: {
                labels: {
                    name: "Nombre",
                    unit: "Unidad",
                    factorType: "Tipo de factor",
                    qualityFactor: "Factor calidad",
                    countries: "Paises"
                },
                rules: {
                    nameRequired: "Nombre es requerido",
                    unitRequired: "Unidad es requerido",
                    countriesRequired: "Paises es requerido.",
                    factorTypeRequired: "Tipo de factor es requerido",
                    nameLength: "Nombre no debe ser superior a 60 caracteres",
                    unitLength: "Unidad no debe ser superior a 60 caracteres",
                    
                },
                swal: {
                    create: {
                        titleConfirm: "¿Desea enviar los datos del factor?",
                        titleSuccess: "Se ha creado el factor con éxito"
                    },
                    edit: {
                        titleConfirm: "¿Desea editar los datos del factor?",
                        titleSuccess: "Se ha editado el factor con éxito"
                    },
                    destroy: {
                        titleConfirm: "¿Desea eliminar los datos del factor?",
                        titleSuccess: "Se ha eliminado el factor con éxito"
                    }
                }
            }
        }
    }
}