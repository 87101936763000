export default {
  namespaced: true,
    state:{    
        users: [],    
        userCreate: {
            modal: false,
            values: {}
        },
        userEdit: {
            modal: false,
            values: {}
        }
    },
    getters:{
        users(state){
            return state.users;
        },   
        userCreate(state) {
            return state.userCreate
        },
        userEdit(state) {
            return state.userEdit
        }    
    },
    mutations:{
        SET_USERS(state, values){
            state.users = values;
        },
        SET_USER_CREATE_MODAL(state, value) {
            state.userCreate.modal = value
        },
        SET_USER_CREATE_VALUES(state, value) {
            state.userCreate.values = value
        },
        SET_USER_EDIT_MODAL(state, value) {
            state.userEdit.modal = value
        },
        SET_USER_EDIT_VALUES(state, value) {
            state.userEdit.values = value
        },
        PUSH_USER(state, value){
            state.users.push(value);
        },    
        SET_UPDATE_USER(state, values){
            var editedIndex = state.users.map(user => user.id).indexOf(values.id)      
            if (editedIndex > -1){
                Object.assign(state.users[editedIndex], values)              
            }
            return;
        },
        SPLICE_USER(state, value){
            let i = state.users.map(user => user.id).indexOf(value)      
            state.users.splice(i, 1)
        }
    },
    actions:{
        getUsers({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('admin/users').then((response) => {
                    commit('SET_USERS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        userCreate({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('admin/users/create').then((response) =>{
                    commit('SET_USER_CREATE_VALUES', response.data.data)
                    commit('SET_USER_CREATE_MODAL', true)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        userStore({commit}, payload) {
            const formData = new FormData();
            for(var key in payload) {
                formData.append(key, payload[key]);
            }
            return new Promise((resolve, reject) => {
                axios.post('admin/users', formData).then((response) => {
                    commit('PUSH_USER', response.data.data);          
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        userEdit({ commit }, value) {
            return new Promise((resolve, reject) => {
                axios.get('admin/users/' + value + '/edit').then((response) =>{
                    commit('SET_USER_EDIT_VALUES', response.data.data)
                    commit('SET_USER_EDIT_MODAL', true)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        userUpdate({commit}, payload) {
            var value = payload.id;            
            return new Promise((resolve, reject) => {
                axios.put('admin/users/' + value, payload).then((response) => {          
                    commit('SET_UPDATE_USER', response.data.data);
                    resolve(response)   
                })
                .catch((error) => {
                    reject(error)
                })
            });
        },
        deleteUser({ commit }, value) {
            return new Promise((resolve, reject) => {
                axios.delete('admin/users/' + value).then((response) => {
                    commit('SPLICE_USER', value);
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            });      
        }
    }
}