<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-col cols="12" sm="12" class="d-flex justify-center">
          <router-view :key="$route.path"></router-view>             
        </v-col>
      </v-row>       
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'LoginLayout',
   data(){
    return{
      
    }
  }
}
</script>